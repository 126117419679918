/* CustomToast.css */

/* Cambiar estilo y color del texto */
.custom-toast-header, .custom-toast-body {
  color: white; /* Cambiar el color del texto */
  font-size: 16px; /* Cambiar el tamaño de fuente */
  text-align: center; /* Centrar el texto */
  padding: 10px; /* Añadir relleno alrededor del texto */
  /* Agrega otros estilos personalizados según tus necesidades */
}

/* Cambiar la posición del Toast */
.custom-toast {
  position: fixed;
  top: 0; /* Posición superior */
  left: 50%; /* Ajusta la posición horizontal según sea necesario */  
  transform: translateX(-50%); /* Centrar horizontalmente el Toast */
  z-index: 9999; /* Establecer una alta capa z-index para que se superponga a otros elementos */
}