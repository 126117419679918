/* Agregar un prefijo específico */
.actas-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.actas-content.editing {
  max-width: 100%;
}

.actas-upload-section {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.actas-info-section {
  margin-bottom: 20px;
}

.actas-table {
  width: 100%;
}

.actas-table th,
.actas-table td {
  text-align: center;
  vertical-align: middle;
}

.actas-table-responsive {
  overflow-x: auto;
}
